import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import RWDropdownList from 'react-widgets/lib/DropdownList';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import {
    Formik,
    Form,
    Field,
    CccisdFieldWrapper,
    CccisdSelect,
    CccisdInput,
    CccisdWysiwyg,
} from 'cccisd-formik';
import style from './style.css';
import assignmentsQuery from './assignments.graphql';
import variablesQuery from './variables.graphql';

const BlockForm = ({ form: reportForm, closeModal, editBlock }) => {
    const initialSelectedAssignmentId =
        editBlock && editBlock.type === 'variable' ? editBlock.assignmentId : '';
    const [assignments, setAssignments] = useState([]);
    const [isLoadingAssignments, setIsLoadingAssignments] = useState(true);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState(initialSelectedAssignmentId);
    const [variables, setVariables] = useState([]);
    const [isLoadingVariables, setIsLoadingVariables] = useState(true);

    useEffect(() => {
        async function getAssignments() {
            setIsLoadingAssignments(true);
            const resp = await client.query({
                query: assignmentsQuery,
                fetchPolicy: 'network-only',
            });

            const options = _get(resp, 'data.flows.assignmentList', []) || [];
            if (!options[0] || options[0].assignmentId !== '') {
                options.unshift({ assignmentId: '', label: '-- Select Quest --', disabled: true });
            }
            setAssignments(options);
            setIsLoadingAssignments(false);
        }

        getAssignments();
    }, []);

    useEffect(() => {
        async function getVariables() {
            setIsLoadingVariables(true);
            const resp = await client.query({
                query: variablesQuery,
                variables: {
                    assignmentId: selectedAssignmentId,
                },
                fetchPolicy: 'network-only',
            });

            setVariables(_get(resp, 'data.metrics.variableList', []) || []);
            setIsLoadingVariables(false);
        }

        if (selectedAssignmentId) {
            getVariables();
        }
    }, [assignments, selectedAssignmentId]);

    const handleSubmit = localFormValues => {
        const newBlock = { type: localFormValues.type };

        // each block needs unique identifier for drag-n-drop
        newBlock.id = editBlock
            ? editBlock.id
            : reportForm.values.blocks.reduce((max, curr) => (max.id > curr.id ? max : curr), {
                  id: 0,
              }).id + 1;

        if (localFormValues.type === 'variable') {
            newBlock.typeText = 'Variable';
            newBlock.assignmentId = localFormValues.assignmentId;
            newBlock.variable = variables.find(v => v.variableId === localFormValues.variableId);
            newBlock.questionText = localFormValues.questionText;
        } else if (localFormValues.type === 'html') {
            newBlock.htmlContent = localFormValues.htmlContent;
            newBlock.typeText = 'HTML';
        }

        if (editBlock) {
            const updatedBlocks = [...reportForm.values.blocks];
            const editIndex = updatedBlocks.findIndex(block => block.id === editBlock.id);
            updatedBlocks[editIndex] = newBlock;
            reportForm.setFieldValue('blocks', updatedBlocks);
        } else {
            reportForm.setFieldValue('blocks', [...reportForm.values.blocks, newBlock]);
        }
        closeModal();
    };

    const validate = values => {
        let errors = {};

        switch (values.type) {
            case 'variable':
                if (!values.assignmentId) {
                    errors.assignmentId = 'Required';
                }
                if (!values.variableId) {
                    errors.variableId = 'Required';
                }
                break;
            case 'html':
                if (!values.htmlContent) {
                    errors.htmlContent = 'Required';
                }
                break;
            default:
                errors.type = 'Required';
        }

        return errors;
    };

    const renderVariableFields = localForm => {
        if (isLoadingAssignments) {
            return <Loader loading type="inline" />;
        }

        const isAssignmentValid =
            selectedAssignmentId &&
            assignments.some(opt => Number(opt.assignmentId) === Number(selectedAssignmentId));

        return (
            <div>
                <Field name="assignmentId">
                    {fieldProps => (
                        <CccisdSelect
                            {...fieldProps}
                            label="Quest:"
                            options={assignments.map(a => ({
                                value: a.assignmentId,
                                ...a,
                            }))}
                            onChange={event => {
                                const newVal = event.currentTarget.value;
                                setSelectedAssignmentId(newVal);
                                localForm.setFieldValue('assignmentId', newVal);
                                localForm.setFieldValue('variableId', '');
                                localForm.setFieldValue('questionText', '');
                            }}
                        />
                    )}
                </Field>
                <Field name="variableId">
                    {fieldProps => (
                        <CccisdFieldWrapper {...fieldProps} label="Variable:">
                            <RWDropdownList
                                data={variables.filter(
                                    v =>
                                        !reportForm.values.blocks.find(
                                            block =>
                                                block.type === 'variable' &&
                                                block.variable &&
                                                block.variable.variableId === v.variableId
                                        )
                                )}
                                busy={isAssignmentValid && isLoadingVariables}
                                disabled={!isAssignmentValid}
                                autoComplete="on"
                                onChange={val => {
                                    localForm.setFieldValue('variableId', val.variableId);
                                    const selectedVar =
                                        val.variableId &&
                                        variables.find(
                                            v => Number(v.variableId) === Number(val.variableId)
                                        );

                                    const defaultQuestionText = _get(
                                        selectedVar,
                                        'content.title',
                                        ''
                                    );
                                    localForm.setFieldValue('questionText', defaultQuestionText);
                                }}
                                value={variables.find(
                                    v => v.variableId === localForm.values.variableId
                                )}
                                dataKey="variableId"
                                textField="reportHeader"
                            />
                        </CccisdFieldWrapper>
                    )}
                </Field>
                <Field
                    name="questionText"
                    label="Question Text:"
                    component={CccisdInput}
                    disabled={!localForm.values.variableId}
                />
            </div>
        );
    };

    const renderHtmlFields = () => (
        <Field name="htmlContent" label="Content:" component={CccisdWysiwyg} type="textarea" />
    );

    const intialValues = {
        type: editBlock ? editBlock.type : 'variable',
        assignmentId: initialSelectedAssignmentId,
        variableId: editBlock && editBlock.type === 'variable' ? editBlock.variable.variableId : '',
        questionText: editBlock && editBlock.type === 'variable' ? editBlock.questionText : '',
        htmlContent: editBlock && editBlock.type === 'html' ? editBlock.htmlContent : '',
    };

    return (
        <Formik initialValues={intialValues} onSubmit={handleSubmit} validate={validate}>
            {localForm => (
                <Form>
                    <Field
                        name="type"
                        label="Type:"
                        component={CccisdSelect}
                        options={[
                            { value: 'variable', label: 'Variable' },
                            { value: 'html', label: 'HTML' },
                        ]}
                    />

                    {localForm.values.type === 'variable' && renderVariableFields(localForm)}
                    {localForm.values.type === 'html' && renderHtmlFields(localForm)}

                    <div className={style.btnContainer}>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={localForm.submitForm}
                        >
                            {editBlock ? 'Save' : 'Add Block'}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default BlockForm;
