import React from 'react';
import PropTypes from 'prop-types';
import PrimaryNav from '../../components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import { NavLink } from 'cccisd-laravel-boilerplate';
import Footer from '../../components/Footer';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: false,
    };

    render() {
        const { showSecondaryNav, className, children } = this.props;

        // ubers have too many nav links; need secondary nav
        const isUberSecondaryNav = Fortress.user.acting.role.handle === 'uberadmin' && !showSecondaryNav;

        return (
            <div className={style.wrapper}>
                <div className={style.body}>
                    <PrimaryNav className={className} />
                    {isUberSecondaryNav && (
                        <div className={style.uberSecondaryNav}>
                            <div className={className}>
                                <NavLink to="/manage">Manage</NavLink>
                                <NavLink to="/resourcesAdmin">Resources Admin</NavLink>
                                <NavLink to="/quest">Data</NavLink>
                                <li role="presentation" className="dropdown">
                                    <a
                                        className="dropdown-toggle"
                                        data-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Logs <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        <NavLink to="/pageVisits">Page Visits</NavLink>
                                        <NavLink to="/permissionsLog">Permissions</NavLink>
                                        <NavLink to="/resourceView">Resource Views</NavLink>
                                        <NavLink to="/users">Users Log</NavLink>
                                    </ul>
                                </li>
                            </div>
                        </div>
                    )}
                    {showSecondaryNav && Fortress.auth() && (
                        <SecondaryNav className={className} navs={AppDefs.navs} routes={AppDefs.routes} />
                    )}
                    <div className={className}>{children}</div>
                </div>

                <div className={style.footer}>
                    <Footer className={className} copyrightYear="2021" />
                </div>
            </div>
        );
    }
}
