import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import Form from './form.js';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export default class ActivationForm extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        formName: PropTypes.string,
        activationToken: PropTypes.string.isRequired,
        alsoLogin: PropTypes.bool,
    };

    state = {
        username: null,
        fullName: null,
        isLoading: true,
    };

    componentDidMount = async () => {
        const { activationToken } = this.props;

        const response = await axios.get(
            Boilerplate.route('api.nexus.user.verifyActivationUser', {
                activation_token: activationToken,
            })
        );

        if (response.data.status === 'success') {
            if (response.data.data.activated_at) {
                // user is already activated.
                window.location = Boilerplate.url('authRedirect');
                return;
            }

            this.setState({
                username: response.data.data.username,
                fullName: response.data.data.full_name,
                isLoading: false,
            });
        } else {
            window.location = '/';
            throw response.data.errors;
        }
    };

    _onSubmit = async values => {
        values.activationToken = this.props.activationToken;
        if (this.props.alsoLogin) {
            values.login = 1;
        }

        const response = await axios.post(Boilerplate.route('api.nexus.user.activate'), values);
        if (response.data.status === 'success') {
            if (this.props.onSuccess) {
                await this.props.onSuccess(response);
            }
            let redirectUrl = Boilerplate.url(Fortress.settings.config.after_login_url);
            if (response.data.data.hasIncompleteSiteRegistration) {
                redirectUrl = Boilerplate.url('/account/registrationSurvey');
            }
            window.location = redirectUrl;
        } else {
            throw response.data.errors;
        }
    };

    render() {
        if (this.state.isLoading) {
            return <Loader loading />;
        }

        return (
            <>
                <h2>Account Activation</h2>
                <Form
                    onSubmit={this._onSubmit}
                    form={this.props.formName || 'activationForm'}
                    username={this.state.username}
                    fullName={this.state.fullName}
                />
            </>
        );
    }
}
