import React from 'react';
import Table from 'cccisd-graphql-table';
import query from './query.graphql';

const UsersLogTable = () => {
    const columns = [
        { name: 'username', label: 'Username', filter: true, sort: true },
        { name: 'firstName', label: 'First Name', filter: true, sort: true },
        { name: 'lastName', label: 'Last Name', filter: true, sort: true },
        { name: 'email', label: 'Email', filter: true, sort: true },
        {
            name: 'lastLoginAt',
            label: 'Last Login',
            filter: true,
            sort: true,
            filterSettings: {
                type: 'date',
            },
        },
        {
            name: 'pawnsList',
            label: 'Accounts',
            filter: false,
            sort: false,
            render: ({ value, row, isCsv }) => {
                const pawns = row.pawnList.map(p => {
                    return p.role.label + (p.pawn.archived ? ' (ARCHIVED)' : '');
                });
                if (isCsv) {
                    return pawns.join();
                }
                return (
                    <ul style={{ paddingLeft: '1.0em' }}>
                        {pawns.map((p, index) => (
                            <li key={index}>{p}</li>
                        ))}
                    </ul>
                );
            },
        },
        {
            name: 'groups',
            label: 'Groups',
            render: ({ value, row, isCsv }) => {
                if (row.pawnList.some(p => p?.role?.label === 'Respondent')) {
                    const deployments = [];
                    row.pawnList.forEach(
                        p =>
                            p?.assignmentProgressList &&
                            p.assignmentProgressList.forEach(ap => {
                                deployments.push(`${ap?.assignment?.label || ''} - ${ap?.deployment?.label || ''}`);
                            })
                    );

                    return (
                        <ul style={{ paddingLeft: '1.0em' }}>
                            {deployments.map((p, index) => (
                                <li key={index}>{p}</li>
                            ))}
                        </ul>
                    );
                }

                if (!row.pawnList.some(p => p?.parentGroup?.anyGroup?.group?.label)) {
                    return <span />;
                }

                const groups = row.pawnList.map(p => {
                    const g = p?.parentGroup?.anyGroup?.group;
                    if (!g || !g?.label) {
                        return '';
                    }

                    return g.label + (g.archived ? ' (ARCHIVED)' : '');
                });
                if (isCsv) {
                    return groups.join();
                }
                return (
                    <ul style={{ paddingLeft: '1.0em' }}>
                        {groups.map((p, index) => (
                            <li key={index}>{p}</li>
                        ))}
                    </ul>
                );
            },
        },
    ];

    return <Table name="users" query={query} columns={columns} rowKey="username" />;
};

export default UsersLogTable;
