import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Field } from 'cccisd-formik';
import IconArrow from 'cccisd-icons/arrow-resize4';
import IconBin from 'cccisd-icons/bin';
import IconPencil from 'cccisd-icons/pencil';
import IconPlus from 'cccisd-icons/plus-circle';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import { ImagePicker } from 'cccisd-laravel-resources';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import widgets from '../../widgets';
import BlockForm from './BlockForm';

export const initialValues = {
    blocks: [],
    header: null,
    footer: null,
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const AnswerSummaryBuilder = () => {
    const handleDelete = (id, form) => {
        form.setFieldValue(
            'blocks',
            form.values.blocks.filter(block => block.id !== id)
        );
    };

    const onDragEnd = (event, form) => {
        const {
            destination: { index: toIndex },
            source: { index: fromIndex },
        } = event;

        let newValues = [];
        const targetValue = form.values.blocks[fromIndex];
        const filteredValues = form.values.blocks.filter((v, i) => i !== fromIndex);
        for (let i = 0; i < form.values.blocks.length; i++) {
            if (i < toIndex) {
                newValues.push(filteredValues[i]);
            } else if (i === toIndex) {
                newValues.push(targetValue);
            } else {
                newValues.push(filteredValues[i - 1]);
            }
        }

        form.setFieldValue('blocks', newValues);
    };

    const renderDraggable = (block, i, form) => (
        <Draggable draggableId={'' + block.id} index={i} key={block.id}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={style.draggable}
                >
                    <span>
                        <IconArrow />
                        <h6 className={style.typeText}>{block.typeText}</h6>
                        {block.type === 'variable' && <span>- {block.variable.reportHeader}</span>}
                    </span>
                    <span>
                        <Modal
                            title={`Edit: Block ${i + 1}`}
                            trigger={
                                <Tooltip title="Edit">
                                    <button
                                        type="button"
                                        className={'btn btn-xs btn-success ' + style.editBtn}
                                    >
                                        <IconPencil />
                                    </button>
                                </Tooltip>
                            }
                            render={({ closeModal }) => (
                                <BlockForm form={form} closeModal={closeModal} editBlock={block} />
                            )}
                        />
                        <Tooltip title="Delete">
                            <button
                                type="button"
                                className="btn btn-xs btn-danger"
                                onClick={() => handleDelete(block.id, form)}
                            >
                                <IconBin />
                            </button>
                        </Tooltip>
                    </span>
                </div>
            )}
        </Draggable>
    );

    const renderDragDrop = ({ field, form }) => (
        <DragDropContext onDragEnd={event => onDragEnd(event, form)}>
            <Droppable droppableId="blocks_list">
                {dropProvided => (
                    <div ref={dropProvided.innerRef} className={style.droppable}>
                        <h5>Blocks List</h5>
                        {field.value.length === 0 && (
                            <p className={'help-block ' + style.helpBlock}>
                                No blocks have been added yet.
                            </p>
                        )}
                        {field.value.map((block, i) => renderDraggable(block, i, form))}
                        {dropProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

    const renderImagePickerField = settingsKey => (
        <Field name={settingsKey}>
            {({ form }) => {
                const currVal = form.values[settingsKey];
                return (
                    <>
                        {currVal && (
                            <img
                                src={currVal.url}
                                alt={currVal.name}
                                className={style.imagePreview}
                            />
                        )}
                        <ImagePicker
                            trigger={
                                <button className="btn btn-default" type="button">
                                    {currVal ? 'Change' : 'Select'} Image
                                </button>
                            }
                            onChange={imgMeta => form.setFieldValue(settingsKey, imgMeta)}
                            value={currVal || {}}
                        />
                        {currVal && (
                            <button
                                type="button"
                                className={'btn btn-danger ' + style.removeImage}
                                onClick={() => form.setFieldValue(settingsKey, null)}
                            >
                                <IconBin />
                                &nbsp;&nbsp;Remove
                            </button>
                        )}
                    </>
                );
            }}
        </Field>
    );

    return (
        <>
            <BuilderBlock title="Header" isVisibleOverflow>
                {renderImagePickerField('header')}
            </BuilderBlock>
            <BuilderBlock title="Footer" isVisibleOverflow>
                {renderImagePickerField('footer')}
            </BuilderBlock>
            <BuilderBlock title="Blocks" isVisibleOverflow>
                <Field name="blocks">
                    {({ field, form }) => (
                        <>
                            {renderDragDrop({ field, form })}
                            <Modal
                                title="Add Block"
                                trigger={
                                    <button type="button" className="btn btn-default">
                                        <IconPlus />
                                        &nbsp;&nbsp;Add Block
                                    </button>
                                }
                                render={({ closeModal }) => (
                                    <BlockForm form={form} closeModal={closeModal} />
                                )}
                                size="670px"
                            />
                        </>
                    )}
                </Field>
            </BuilderBlock>
        </>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this Component with Filter and Widget Fields.
export default reportTemplateBuilder({ widgets })(AnswerSummaryBuilder);
