import React from 'react';
import TableTop, { visualizeButton } from 'cccisd-tabletop';
import resourceViewByViewer from './resourceViewByViewer.graphql';
import resourceViewVizQuery from './resourceViewVisualization.graphql';
import resourceViewByView from './resourceViewByView.graphql';
import resourceViewCharts from './resourceViewViz';
import moment from 'moment';
import Renders from 'cccisd-table-renders';

const Boilerplate = window.cccisd.boilerplate;

const linkRender = ({ value, row }) =>
    typeof value === 'string' ? (
        <a href={Boilerplate.url(value)} style={{ whiteSpace: 'nowrap' }}>
            {Renders.html.Shorten({ maxLength: 40, tail: 10 })({ value })}
        </a>
    ) : (
        value
    );

const resourceLinkRender = ({ value, row }) =>
    row['resource.resourceId'] ? (
        <a target="log_resource" href={Boilerplate.url(row.url) + '/' + row['resource.resourceId']}>
            {Renders.html.Shorten({ maxLength: 40, tail: 10 })({ value })}
        </a>
    ) : (
        value
    );

const resourceViewTable = () => {
    const maxDate = moment().format('YYYY-MM-DD');
    const minDate = moment()
        .subtract(60, 'days')
        .format('YYYY-MM-DD');
    const variables = { minDate, maxDate };

    const byView = {
        query: resourceViewByView,
        rowKey: 'eventId',
        isAscentOrder: false,
        handle: 'resourceViewByView',
        visualize: {
            query: resourceViewVizQuery,
            charts: resourceViewCharts,
            variables,
        },
        columns: [
            {
                name: 'eventAt',
                label: 'View Date',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
                width: '170px',
            },
            {
                name: 'resource.resourceId',
                label: 'Resource ID',
                hideInTable: true,
            },
            {
                name: 'resource.label',
                label: 'Resource',
                sort: true,
                filter: true,
                render: resourceLinkRender,
            },
            {
                name: 'url',
                label: 'Resource Center',
                sort: true,
                filter: true,
                render: linkRender,
            },
            {
                name: 'pawn.pawn.pawnId',
                label: 'Viewer ID',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.pawn.roleName',
                label: 'Role',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.user.username',
                label: 'Username',
                sort: true,
                filter: true,
            },
        ],
    };

    const byViewer = {
        query: resourceViewByViewer,
        rowKey: 'pawn.pawnId',
        isAscentOrder: false,
        handle: 'resourceViewByViewer',
        visualize: {
            query: resourceViewVizQuery,
            charts: resourceViewCharts,
            variables,
        },
        columns: [
            {
                name: 'pawn.pawnId',
                label: 'Viewer ID',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.roleName',
                label: 'Role',
                sort: true,
                filter: true,
            },
            {
                name: 'user.username',
                label: 'Username',
                sort: true,
                filter: true,
            },
            {
                name: 'eventCount',
                label: '# Views',
                sort: true,
                filter: true,
            },
            {
                name: 'eventSummary.minDate',
                label: 'First view',
                width: '170px',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
            },
            {
                name: 'eventSummary.maxDate',
                label: 'Latest view',
                width: '170px',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
            },
        ],
    };

    return (
        <div>
            <TableTop
                handle="resourceViews"
                saveState
                views={[
                    {
                        handle: 'resourceViewByView',
                        title: 'Resource Views - by View',
                        table: byView,
                        buttons: [visualizeButton],
                    },
                    {
                        handle: 'resourceViewByViewer',
                        title: 'Resource Views - by Viewer',
                        table: byViewer,
                    },
                ]}
            />
        </div>
    );
};

export default resourceViewTable;
