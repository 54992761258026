// import React from 'react';

const charts = [
    {
        title: 'Page visits in last 60 days',
        showTotal: true,
        totalLabel: 'Total page visits',
        type: 'line',
        singleSeriesLabel: 'Visits',
        dataPath: 'events.eventSummary.byDay',
        nivo: {
            curve: 'basis',
            enableArea: true,
            enablePoints: false,
            xScale: {
                type: 'time',
                format: '%Y-%m-%d',
                precision: 'day',
                useUTC: false,
            },
            xFormat: 'time:%Y-%m-%d',
            axisLeft: {
                legend: 'Number of visitors',
                legendPosition: 'middle',
                legendOffset: -42,
            },
            axisBottom: {
                format: '%m-%d',
                tickValues: 'every 8 days',
                legend: 'Day',
                legendPosition: 'middle',
                legendOffset: 42,
            },
        },
    },
    {
        title: 'Page visits by URL',
        type: 'pie',
        dataPath: 'events.eventSummary.byUrl',
        height: '700px',
        showFrequencyTable: false,
        nivo: {
            margin: { top: 0, right: 150, bottom: 100, left: 180 },
            legends: [
                {
                    height: '600px',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 140,
                    translateY: 100,
                    itemWidth: 150,
                    itemHeight: 12,
                    itemsSpacing: 5,
                    itemDirection: 'left-to-right',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 0.8,
                            },
                        },
                    ],
                },
            ],
        },
    },
];

export default charts;
