import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { TextBlock, FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import style from './style.css';

export const fields = ['password', 'repeatPassword', 'termsandconds'];

const validate = (values, props) => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = 'The passwords must match';
    }

    if (!values.termsandconds) {
        errors.termsandconds = 'The privacy policy and terms of use must be accepted to continue';
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        username: PropTypes.string,
        fullName: PropTypes.string,
    };

    render() {
        const {
            fields: { password, repeatPassword, termsandconds },
            handleSubmit,
            submitting,
            username,
            fullName,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <h3>Welcome, {fullName || username}!</h3>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <span style={{ fontSize: '20px' }}>
                            Create a password for your account with username{' '}
                            <strong>{username}</strong>
                        </span>
                    </div>
                    <div className="panel-body">
                        <div style={{ maxWidth: '350px' }}>
                            <TextBlock field={password} label="Create Password" type="password" />
                        </div>
                        <div style={{ maxWidth: '350px' }}>
                            <TextBlock
                                field={repeatPassword}
                                label="Confirm Password"
                                type="password"
                            />
                        </div>
                    </div>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                            3C Privacy Policy and Terms of Use
                        </span>
                    </div>
                    <div className="panel-body">
                        <div className={style.terms}>
                            <ul>
                                <li>
                                    <a
                                        href="https://www.3cisd.com/privacy-policy?page_id=3110"
                                        target="blank"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.3cisd.com/terms-of-use" target="blank">
                                        Terms of Use
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.traumascreentime.org/index.php/additional-terms/"
                                        target="blank"
                                    >
                                        Supplemental Terms
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <FieldBlock field={termsandconds}>
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" {...domOnlyProps(termsandconds)} />I
                                    accept the privacy policy, terms of use, and supplemental terms.
                                </label>
                            </div>
                        </FieldBlock>
                    </div>
                </div>

                <ClickButton
                    className="btn btn-primary"
                    title="Activate"
                    isLoading={submitting}
                    onClick={handleSubmit}
                />
            </form>
        );
    }
}

export default reduxForm({
    fields,
    initialValues: { password: '', repeatPassword: '', termsandconds: false },
    validate,
})(Form);
