import React, { useState, useEffect } from 'react';
import _set from 'lodash/set';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { Route, withRouter, useParams } from 'cccisd-react-router';
import PlatformMediator from 'cccisd-laravel-assignment/dist/components/navigations/courseWrapper/Mediator/CourseWrapperMediator';
import query from './certificateComplete.graphql';

const MediatorRoot = ownProps => {
    const { actingPawnId, mediatorProps } = ownProps;
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuppressCertificate, setIsSuppressCertificate] = useState(null);

    useEffect(() => {
        async function getAssignmentProgress() {
            const resp = await client.query({
                query,
                variables: { pawnId: actingPawnId },
                fetchPolicy: 'network-only',
            });

            const completedCourses = resp.data.roles.anyRole?.assignmentProgressList || [];
            let suppress = false;
            if (Array.isArray(completedCourses) && completedCourses.length > 0) {
                const firstCompletedHash = completedCourses[0]?.deployment?.hash;
                suppress = firstCompletedHash !== params.deploymentHash;
            }

            setIsSuppressCertificate(suppress);
            setIsLoading(false);
        }

        getAssignmentProgress();
    }, []);

    if (isLoading) {
        return <Loader loading type="inline" />;
    }

    let editedMediatorProps = { ...mediatorProps };
    if (isSuppressCertificate) {
        _set(editedMediatorProps, 'certificate.toggle', false);
    }

    return (
        <Route
            path={`${ownProps.match.path}/:courseWrapperTab?`}
            render={props => (
                <PlatformMediator
                    {...ownProps}
                    {...props}
                    basePath={ownProps.match.url}
                    mediatorProps={editedMediatorProps}
                />
            )}
        />
    );
};

export default withRouter(MediatorRoot);
