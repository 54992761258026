import React from 'react';
import TableTop, { visualizeButton } from 'cccisd-tabletop';
import Renders from 'cccisd-table-renders';
import pageVisitByVisit from './pageVisitByVisit.graphql';
import pageVisitByVisitor from './pageVisitByVisitor.graphql';
import pageVisitByUrl from './pageVisitByUrl.graphql';
import pageVisitVizQuery from './pageVisitVisualization.graphql';
import pageVisitCharts from './pageVisitViz.js';
import moment from 'moment';

const Boilerplate = window.cccisd.boilerplate;

const linkRender = ({ value, row }) =>
    typeof value === 'string' ? (
        <a href={Boilerplate.url(value)} style={{ whiteSpace: 'nowrap' }}>
            {Renders.html.Shorten({ maxLength: 40, tail: 10 })({ value })}
        </a>
    ) : (
        value
    );

const durationRender = ({ value, row }) => {
    let duration = value;

    if (duration === 0) {
        return '< 1 second';
    }

    if (duration < 60) {
        return duration + ' second' + (duration === 1 ? '' : 's');
    }

    duration /= 60.0;

    if (duration < 60) {
        duration = duration.toFixed(1);
        return duration + ' minutes';
    }

    duration /= 60;

    if (duration < 60) {
        duration = duration.toFixed(1);
        return duration + ' hours';
    }

    duration /= 24;
    duration = duration.toFixed(1);

    return duration + ' days';
};

const PageVisitTable = () => {
    const maxDate = moment().format('YYYY-MM-DD');
    const minDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
    const variables = { minDate, maxDate };

    const byView = {
        query: pageVisitByVisit,
        rowKey: 'eventId',
        isAscentOrder: false,
        handle: 'pageVisitByVisit',
        visualize: {
            query: pageVisitVizQuery,
            charts: pageVisitCharts,
            variables,
        },
        columns: [
            {
                name: 'eventAt',
                label: 'Visit Date',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
                width: '170px',
            },
            {
                name: 'durationSeconds',
                label: 'Time Spent',
                sort: true,
                render: durationRender,
                hideInCsv: true,
            },
            {
                name: 'durationSeconds',
                label: 'Duration (seconds)',
                hideInTable: true,
            },
            {
                name: 'url',
                label: 'Page',
                sort: true,
                filter: true,
                render: linkRender,
            },
            {
                name: 'pawnId',
                label: 'Visitor ID',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.pawn.roleName',
                label: 'Role',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.user.username',
                label: 'Username',
                sort: true,
                filter: true,
            },
        ],
    };

    const byVisitor = {
        query: pageVisitByVisitor,
        rowKey: 'pawn.pawnId',
        handle: 'pageVisitByVisitor',
        visualize: {
            query: pageVisitVizQuery,
            charts: pageVisitCharts,
            variables,
        },
        columns: [
            {
                name: 'pawn.pawnId',
                label: 'Visitor ID',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.roleName',
                label: 'Role',
                sort: true,
                filter: true,
            },
            {
                name: 'user.username',
                label: 'Username',
                sort: true,
                filter: true,
            },
            {
                name: 'eventSummary.duration',
                label: 'Total duration',
                sort: true,
                render: durationRender,
                hideInCsv: true,
            },
            {
                name: 'eventSummary.duration',
                label: 'Duration (seconds)',
                hideInTable: true,
            },
            {
                name: 'eventCount',
                label: 'Page visits',
                sort: true,
                filter: true,
            },
            {
                name: 'eventSummary.minDate',
                label: 'First visit',
                width: '170px',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
            },
            {
                name: 'eventSummary.maxDate',
                label: 'Latest visit',
                width: '170px',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
            },
        ],
    };

    const byUrl = {
        query: pageVisitByUrl,
        rowKey: 'value',
        handle: 'pageVisitByUrl',
        visualize: {
            query: pageVisitVizQuery,
            charts: pageVisitCharts,
            variables,
        },
        columns: [
            {
                name: 'value',
                label: 'URL',
                render: linkRender,
                sort: true,
                filter: true,
            },
            {
                name: 'valueCount',
                label: 'Page visits',
                sort: true,
                filter: true,
            },
            {
                name: 'duration',
                label: 'Total duration',
                render: durationRender,
                hideInCsv: true,
                sort: true,
            },
            {
                name: 'duration',
                label: 'Duration (seconds)',
                hideInTable: true,
            },
            {
                name: 'minDate',
                label: 'First visit',
                width: '170px',
                filterSettings: { type: 'date' },
                sort: true,
                filter: true,
            },
            {
                name: 'maxDate',
                label: 'Latest visit',
                width: '170px',
                filterSettings: { type: 'date' },
                sort: true,
                filter: true,
            },
        ],
    };

    return (
        <div>
            <TableTop
                handle="pageVisits"
                saveState
                views={[
                    {
                        handle: 'pagesByView',
                        title: 'Page Visits - by Visit',
                        table: byView,
                        buttons: [visualizeButton],
                    },
                    {
                        handle: 'pagesByVisitor',
                        title: 'Page Visits - by Visitor',
                        table: byVisitor,
                    },
                    {
                        handle: 'pagesByUrl',
                        title: 'Page Visits - by Url',
                        table: byUrl,
                    },
                ]}
            />
        </div>
    );
};

export default PageVisitTable;
