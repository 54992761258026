import React from 'react';
import { Field, CccisdCheckboxInput, CccisdPasswordInput } from 'cccisd-formik';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import notification from 'cccisd-notification';
import axios from 'cccisd-axios';
import { useLocation } from 'react-router-dom';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const hostName = window.location.hostname;
const Fortress = window.cccisd.fortress;

const Register = () => {
    const redirectUrl = new URLSearchParams(useLocation().search).get('redirectUrl');
    const componentProps = {
        registerProps: {
            title: 'Register',
            showPasswordFields: false, // show in renderAdditionalFields
            role: 'learner',
            renderAdditionalFields: () => {
                return (
                    <div>
                        <Field
                            name="password"
                            autoComplete="new-password" // stop the browser from autocompleting both the Register form and Login form
                            component={CccisdPasswordInput}
                            showPasswordToggle
                            label="Password:"
                        />
                        <Field
                            name="repeatPassword"
                            autoComplete="new-password" // stop the browser from autocompleting both the Register form and Login form
                            component={CccisdPasswordInput}
                            showPasswordToggle
                            label="Confirm Password:"
                        />
                        <Field
                            name="termsandconds"
                            label={
                                <span>
                                    I accept the{' '}
                                    <a
                                        href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        privacy policy
                                    </a>
                                    ,{' '}
                                    <a
                                        href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        terms of use
                                    </a>
                                    , and{' '}
                                    <a
                                        href="https://www.traumascreentime.org/index.php/additional-terms/"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        supplemental terms
                                    </a>
                                    .
                                </span>
                            }
                            component={CccisdCheckboxInput}
                            labelNotBold
                        />
                    </div>
                );
            },
            onRegisterSubmit: async values => {
                // Must copy values before manipulating or it will mutate Formik state object
                const formValues = { ...values };

                formValues.role = 'learner';
                formValues.username = formValues.username || formValues.email;

                const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), formValues);

                if (response.status !== 200) {
                    notification({
                        message: 'Could not register at this time.',
                        type: 'danger',
                        duration: 5000,
                        data: response.data && response.data,
                    });
                    return;
                }
                if (response.data.status === 'success') {
                    const loginResponse = await axios.post(Boilerplate.route('api.nexus.login'), {
                        username: formValues.username,
                        password: values.password,
                    });
                    if (loginResponse.status !== 200) {
                        notification({
                            message: 'Registration was successful, but you could not login at this time.',
                            type: 'danger',
                            duration: 5000,
                        });
                        return;
                    }

                    if (loginResponse.data.status === 'success') {
                        if (loginResponse.data.data.hasIncompleteSiteRegistration) {
                            window.location = Boilerplate.url(`/account/registrationSurvey?redirectUrl=${redirectUrl}`);
                        } else if (redirectUrl) {
                            window.location = redirectUrl;
                        } else {
                            window.location = Boilerplate.url(Fortress.settings.config.after_login_url);
                        }
                    } else if (loginResponse.data.status === 'error') {
                        notification({
                            message: 'Registration was successful, but you could not login at this time.',
                            type: 'danger',
                            duration: 5000,
                            data: loginResponse.data.errors,
                        });
                    }
                } else if (response.data.status === 'error') {
                    // If the email field is being used as username,
                    // replace 'username' error with 'email' error
                    if (!values.username && response.data.errors.username) {
                        response.data.errors.email = response.data.errors.username;
                        delete response.data.errors.username;
                    }

                    // errors may come back as something like {values: "Missing required middle name."},
                    // so these will be displayed in a notification instead of styling in the form
                    const isErrorLocationInForm = !Object.keys(response.data.errors).find(
                        errLocation => !(errLocation in values)
                    );

                    // Throwing errors object will be picked up by formik, and style the incorrect input fields
                    if (isErrorLocationInForm) {
                        throw response.data.errors;
                    } else {
                        notification({
                            message: 'Failed to register',
                            duration: 10000,
                            type: 'danger',
                            data: response.data.errors,
                        });
                    }
                }

                if (response.data.notifications.length > 0) {
                    response.data.notifications.forEach(note => notification(note));
                }
            },

            validateAdditionalFields: values => {
                const errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                if (values.password !== values.repeatPassword) {
                    errors.password = 'Passwords must match.';
                    errors.repeatPassword = 'Passwords must match.';
                }
                if (!values.password) {
                    errors.password = 'Password is required.';
                }
                if (!values.repeatPassword) {
                    errors.repeatPassword = 'Must confirm password.';
                }
                if (!values.termsandconds) {
                    errors.termsandconds = 'Must accept terms and conditions to continue.';
                }
                return errors;
            },
        },
        loginProps: { title: 'Log In', showPasswordToggle: true, buttonText: 'Log In' },
    };

    return <RegisterLoginPage {...componentProps} />;
};

export default Register;
