import React from 'react';
import TableTop from 'cccisd-tabletop';
import Enable from 'cccisd-icons/checkmark';
import Disable from 'cccisd-icons/cross';
import questProjectQuery from './questproject.graphql';
import questProjectAdminQuery from './questprojectadmin.graphql';

const permissionsChangeRender = ({ value, row }) => {
    let ret = [];
    Object.keys(row).forEach((prop, index) => {
        if (prop.substring(0, 9) === 'metadata.') {
            ret.push(
                <div key={'perm_' + prop}>
                    {row[prop] ? (
                        <span className="text-success">
                            <Enable />
                        </span>
                    ) : (
                        <span className="text-danger">
                            <Disable />
                        </span>
                    )}
                    &nbsp;&nbsp;{prop.substring(9)}
                </div>
            );
        }
    });

    return <div>{ret}</div>;
};

const PermissionsTable = () => {
    const byProject = {
        query: questProjectQuery,
        rowKey: 'eventId',
        handle: 'questProjectQuery',
        columns: [
            {
                name: 'eventAt',
                label: 'Change Date',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
                width: '170px',
            },
            {
                name: 'group.group.groupId',
                label: 'Quest Project ID',
                sort: true,
                filter: true,
            },
            {
                name: 'group.group.label',
                label: 'Quest Project Label',
                sort: true,
                filter: true,
            },
            {
                name: 'targetPawn.pawn.pawnId',
                label: 'Quest Project Admin ID',
                sort: true,
                filter: true,
            },
            {
                name: 'targetPawn.user.username',
                label: 'Quest Project Admin Username',
                sort: true,
                filter: true,
            },
            {
                name: 'metadata',
                label: 'Permissions Change',
                sort: true,
                filter: true,
                render: permissionsChangeRender,
            },
        ],
    };

    const byAdmin = {
        query: questProjectAdminQuery,
        rowKey: 'eventId',
        handle: 'questProjectAdminQuery',
        columns: [
            {
                name: 'eventAt',
                label: 'Change Date',
                sort: true,
                filter: true,
                filterSettings: { type: 'date' },
                width: '170px',
            },
            {
                name: 'pawnId',
                label: 'Quest Project Admin ID',
                sort: true,
                filter: true,
            },
            {
                name: 'pawn.user.username',
                label: 'Quest Project Admin Username',
                sort: true,
                filter: true,
            },
            {
                name: 'targetGroup.group.groupId',
                label: 'Quest Project ID',
                sort: true,
                filter: true,
            },
            {
                name: 'targetGroup.group.label',
                label: 'Quest Project Label',
                sort: true,
                filter: true,
            },
            {
                name: 'metadata',
                label: 'Permissions Change',
                sort: true,
                filter: true,
                render: permissionsChangeRender,
            },
        ],
    };

    return (
        <div>
            <TableTop
                handle="permissionsAlterations"
                saveState
                views={[
                    {
                        handle: 'changesByProject',
                        title: 'Permissions Alterations - by Quest Project',
                        table: byProject,
                    },
                    {
                        handle: 'changesByPawn',
                        title: 'Permissions Alterations - by Quest Project Admin',
                        table: byAdmin,
                    },
                ]}
            />
        </div>
    );
};

export default PermissionsTable;
