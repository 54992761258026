import React, { useEffect, useState } from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import query from './trainingList.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';
import Loader from 'cccisd-loader';
import Warning from 'cccisd-icons/notification2';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const Training = () => {
    const [data, courseTestData] = useState(null);

    useEffect(() => {
        async function getTestData() {
            const response = await client.query({
                query,
                variables: {
                    pawnId: Fortress.user.acting.id,
                },
            });
            const newData = _get(response, 'data.roles.anyRole.assignmentProgressList', {});
            courseTestData(newData);
        }
        getTestData();
    }, []);

    function oneCertificate(resourceData, renderDefaultResourceTiles) {
        if (!data) {
            return <Loader loading type="inline" />;
        }
        if (data.length === 0) {
            return renderDefaultResourceTiles(resourceData);
        }
        const finishedFirst = data[0].deploymentId;

        const resourceDataModified = {
            ...resourceData,
            data: resourceData.data.map(course => {
                if (course.courseData.deploymentId === finishedFirst) {
                    return course;
                }
                course.courseData.assignmentProgress.completed = false;
                return course;
            }),
        };

        return renderDefaultResourceTiles(resourceDataModified);
    }

    return (
        <div>
            <div className={'alert alert-dismissible ' + style.alertCustom} role="alert">
                <button
                    type="button"
                    className={'close ' + style.alertWarning}
                    data-dismiss="alert"
                    aria-label="Close"
                >
                    <span aria-hidden="true" className={style.closing}>
                        &times;
                    </span>
                </button>
                <div className={style.warning}>
                    <Warning />
                </div>{' '}
                Continuing education credits/units are only available for the first course you
                successfully complete.
            </div>
            <ResourceCenter handle="training" renderResourceTiles={oneCertificate} />
        </div>
    );
};
export default Training;
