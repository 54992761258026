import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import NctsnLogo from './nctsn_logo_800.png';
import ChdiLogo from './CHDIv2_Footer.svg';
const hostName = window.location.hostname;

const Footer = (props) => {
    return (
        <footer>
            <div className={style.logos}>
                <div className={props.className}>
                    <div className={style.logoFlex}>
                        <img src={NctsnLogo} alt="NCTSN" className={style.nctsn} />
                        <a href="https://www.chdi.org" target="_blank" rel="noopener noreferrer">
                            <img src={ChdiLogo} alt="CHDI" className={style.chdi} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={style.purple}>
                <div className={props.className}>
                    <div className={style.wrapper}>
                        <div>
                            Software developed by{' '}
                            <a
                                href={`//www.3cisd.com?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={style.footerLink}
                            >
                                3C Institute
                            </a>
                            . &copy; {props.copyrightYear}, All Rights Reserved.
                        </div>
                        {props.showPolicyLinks && (
                            <div className={style.links}>
                                <a
                                    href={`//www.3cisd.com/?r=${hostName}/#ccc-lets-talk`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={style.footerLink}
                                >
                                    <span className={style.noWrap}>Contact Us</span>
                                </a>
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={style.footerLink}
                                >
                                    <span className={style.noWrap}>Terms of Use</span>
                                </a>
                                <a
                                    href="https://www.traumascreentime.org/index.php/additional-terms/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={style.footerLink}
                                >
                                    <span className={style.noWrap}>Supplemental Terms</span>
                                </a>
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={style.footerLink}
                                >
                                    <span className={style.noWrap}>Privacy Policy</span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    copyrightYear: new Date().getFullYear(),
};

export default Footer;
