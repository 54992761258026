import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import widgets from '../../widgets';
import style from './style.css';
import metricsQuery from './metrics.graphql';

const Fortress = window.cccisd.fortress;

const AnswerSummaryPlayer = props => {
    const {
        settings: { blocks, header, footer },
    } = props;
    const [metricsData, setMetricsData] = useState(null);

    useEffect(() => {
        async function getMetricsData() {
            const assignmentIds = [];
            const variableIds = [];
            blocks.forEach(block => {
                if (block.type === 'variable') {
                    if (!assignmentIds.includes(block.assignmentId)) {
                        assignmentIds.push(block.assignmentId);
                    }

                    if (!variableIds.includes(block.variable.variableId)) {
                        variableIds.push(block.variable.variableId);
                    }
                }
            });

            const resp = await client.query({
                query: metricsQuery,
                variables: {
                    assignmentIds,
                    variableIds,
                    actingId: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });

            /* variablesMap has this structure
             *   { [assignmentId]: { [reportHeader]: 'metrics_value' } }
             *  Useful for quickest, direct-access recall when rendering blocks
             */
            let variablesMap = {};
            resp.data.roles.anyRole.assignmentProgressList.forEach(ap => {
                if (!variablesMap[ap.assignmentId]) {
                    variablesMap[ap.assignmentId] = {};
                }

                for (const [varName, metricValue] of Object.entries(ap.variables)) {
                    variablesMap[ap.assignmentId][varName] = metricValue;
                }
            });

            setMetricsData(variablesMap);
        }
        getMetricsData();
    }, [blocks]);

    const renderLoader = () => (
        <div className={style.loader}>
            <Loader loading type="inline" />
        </div>
    );

    const renderBlocks = () => (
        <div>
            {blocks.map(block => {
                let content;
                switch (block.type) {
                    case 'variable':
                        // eslint-disable-next-line no-case-declarations
                        const answerText = _get(
                            metricsData,
                            `${block.assignmentId}.${block.variable.reportHeader}`,
                            ''
                        );

                        content = (
                            <>
                                <p className={style.question}>{block.questionText}</p>
                                <p className={`${style.answer} ${!answerText ? style.empty : ''}`}>
                                    {answerText}
                                </p>
                            </>
                        );
                        break;
                    case 'html':
                        content = <Html content={block.htmlContent} />;
                        break;
                    default:
                        return;
                }

                return (
                    <div className={style.block} key={block.id}>
                        {content}
                    </div>
                );
            })}
        </div>
    );

    return (
        <Report>
            <Page>
                <div className={style.pageWrapper}>
                    <div>
                        {!!header && !!header.url && (
                            <header className={style.header}>
                                <img src={header.url} alt="header" />
                            </header>
                        )}
                        {metricsData ? renderBlocks() : renderLoader()}
                    </div>
                    {!!footer && !!footer.url && (
                        <footer>
                            <img src={footer.url} alt="footer" />
                        </footer>
                    )}
                </div>
            </Page>
        </Report>
    );
};

AnswerSummaryPlayer.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => [],
})(AnswerSummaryPlayer);
