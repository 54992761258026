import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import { ImagePicker } from 'cccisd-laravel-resources';
import Tooltip from 'cccisd-tooltip';
import IconInfo from 'cccisd-icons/info2';
import settingsWrapper from 'cccisd-laravel-assignment/dist/helpers/settingsWrapper';
import style from './style.css';
export const initialValues = {
    renderCourseInOverlay: false, // TODO: make this true by default without affecting existing Quests
};

const Settings = ({
    questSettings: {
        navigationSettings: { renderCourseInOverlay, modalHeaderImageUrl, modalHeaderImageFileName },
    },
}) => {
    return (
        <>
            <Field
                name="renderCourseInOverlay"
                component={CccisdToggle}
                label={
                    <span>
                        Show Course in Modal over Description{' '}
                        <Tooltip title="If true, will initially render the course description as a landing page. The page will contain a button that, when clicked, will display the rest of the course material in a modal overlay. The overlay will NOT contain the course description tab.">
                            <IconInfo />
                        </Tooltip>
                    </span>
                }
            />
            {renderCourseInOverlay && (
                <div className={style.modalHeaderImagePicker}>
                    <Field
                        name="modalHeaderImageUrl"
                        render={({ form }) => {
                            return (
                                <ImagePicker
                                    onChange={({ name, url }) => {
                                        form.setFieldValue('modalHeaderImageUrl', url, true);
                                        form.setFieldValue('modalHeaderImageFileName', name, true);
                                    }}
                                    trigger={
                                        <button type="button" className="btn btn-default">
                                            {modalHeaderImageUrl
                                                ? 'Change Modal Header Image'
                                                : 'Upload Modal Header Image'}
                                        </button>
                                    }
                                />
                            );
                        }}
                    />
                    {modalHeaderImageFileName && <span className={style.fileName}>{modalHeaderImageFileName}</span>}
                </div>
            )}
        </>
    );
};

export default settingsWrapper({ initialValues })(Settings);
