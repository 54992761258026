import React from 'react';
import PropTypes from 'prop-types';
import ActivationForm from './Form';
import style from './style.css';

export default class Activate extends React.Component {
    static propTypes = {
        match: PropTypes.object,
    };

    render() {
        return (
            <div className={style.wrapper}>
                <ActivationForm
                    activationToken={this.props.match.params.activationToken}
                    alsoLogin
                />
            </div>
        );
    }
}
