import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarLink, ReturnToUserLink, AdminDropdown, UserDropdown } from 'cccisd-header';
import style from './style.css';
import logo from './CHDILogo.svg';

const Appdefs = window.cccisd.appDefs;
const Env = window.cccisd.env;
const Fortress = window.cccisd.fortress;

const PrimaryNav = ({ className }) => {
    const wpUrl = Env.wpUrl || Appdefs.app.wpUrl;

    // uberadmin have their Manage tab in a secondary nav
    const rolesWithManage = ['orgAdmin', 'guAdmin', 'siteAdmin', 'instructor'];

    const publicLinks = (
        <>
            <NavbarLink to={wpUrl + '/index.php/about'} highlight="index.php/about">
                About
            </NavbarLink>
            <NavbarLink to="/public_resources" highlight="public_resources">
                Resources
            </NavbarLink>
            <NavbarLink to={wpUrl + '/index.php/testimonials'} highlight="index.php/testimonials">
                Testimonials
            </NavbarLink>
            <NavbarLink to={wpUrl + '/index.php/contact'} highlight="index.php/contact">
                Contact
            </NavbarLink>
            <NavbarLink to="/training" highlight="training">
                Training
            </NavbarLink>
            {Fortress && Fortress.auth() && rolesWithManage.includes(Fortress.user.acting.role.handle) && (
                <NavbarLink to="/manage" highlight="manage">
                    Manage
                </NavbarLink>
            )}
        </>
    );

    return (
        <div className={style.navheader}>
            <Navbar
                className={className}
                logo={<img src={logo} className={style.logo} alt="CHDI Logo" />}
                logoLinkTo={wpUrl}
            >
                <ul className="nav navbar-nav navbar-right">
                    {Fortress.auth() ? (
                        <>
                            <ReturnToUserLink />
                            {publicLinks}
                            <AdminDropdown />
                            <UserDropdown allowSwitchRoles />
                        </>
                    ) : (
                        <>
                            {publicLinks}
                            <NavbarLink to="/register" highlight="register">
                                Register<span className={style.registerLoginSlash}>/</span>Log In
                            </NavbarLink>
                        </>
                    )}
                </ul>
            </Navbar>
        </div>
    );
};

PrimaryNav.defaultProps = {
    className: 'container',
};

PrimaryNav.propTypes = {
    className: PropTypes.string,
};

export default PrimaryNav;
